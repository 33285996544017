<template lang="pug">
	.card-basic.overflow-visible.position-relative.create-event
		svg.position-absolute.create-event__dashed-small(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="68" height="44" fill="none")
			circle(cx="34" cy="10" r="33.5" stroke="#52A7F9" stroke-dasharray="10 10" opacity=".3")
		svg.position-absolute.create-event__dashed-medium(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="89" height="110" fill="none")
			circle(cx="25.5" cy="63.5" r="63" stroke="#52A7F9" stroke-dasharray="10 10" opacity=".3")
		svg.position-absolute.create-event__dashed-big(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="372" height="123" fill="none")
			circle(cx="192" cy="192" r="191.5" stroke="#52A7F9" stroke-dasharray="10 10" opacity=".3")
		svg.position-absolute.create-event__dashed-mobile(v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="184" height="26" fill="none")
			circle(cx="92" cy="92" r="91.5" stroke="#52A7F9" stroke-dasharray="10 10" opacity=".4")
		span.position-absolute.rounded-circle.d-flex.justify-content-center.align-items-center.create-event__explosion(:class='{"dark-filter" : isDarkColor}') {{ emoticons[0] }}
		span.position-absolute.rounded-circle.d-flex.justify-content-center.align-items-center.create-event__pencil(:class='{"dark-filter" : isDarkColor}') {{ emoticons[1] }}
		span.position-absolute.rounded-circle.d-flex.justify-content-center.align-items-center.create-event__bag(:class='{"dark-filter" : isDarkColor}') {{ emoticons[2] }}
		span.position-absolute.rounded-circle.d-flex.justify-content-center.align-items-center.create-event__smiley(v-if="!isMobile" :class='{"dark-filter" : isDarkColor}') {{ emoticons[3] }}
		span.btn.btn-big.btn-primary.position-relative.d-flex.justify-content-center.align-items-center.create-event__btn(@click="$emit('click', $event)") {{ text }}
</template>

<script>
export default {
    name: 'CreateEvent',
    props: {
        text: {
            default: '',
            type: String,
        },
        promotion: {
            default: '',
            type: String,
        },
    },
    computed: {
        emoticons() {
            if (this.promotion == 'stocks' || this.promotion == 'promo-codes' || this.promotion == 'comebacker') {
                return ['💥', '✏️', '💰', '🤑'];
            } else if (this.promotion == 'tariff') {
                return ['🚀', '🎁', '📝', '👑'];
            } else if (this.promotion == 'resources' && !this.isMobile) {
                return ['💎', '👩‍💻', '🔥', '💵'];
            } else if (this.promotion == 'resources' && this.isMobile) {
                return ['🔥', '👩‍💻', '💵', '💎'];
            } else {
                return [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.create-event {
    z-index: 1;
    padding: 48px 40px;
    max-width: 935px;

    @include respond-below(sm) {
        padding: 21px 19px 54px;
    }

    &__dashed-small {
        top: 0;
        right: 305px;
    }

    &__dashed-medium {
        bottom: 0;
        left: 1px;
    }

    &__dashed-big {
        bottom: 0;
        right: -18px;
    }

    &__dashed-mobile {
        bottom: 0;
        right: 6px;
    }

    &__btn {
        width: 100%;
        height: 60px;
        max-width: 524px;

        @include respond-below(sm) {
            height: 55px;
        }
    }

    &__explosion {
        bottom: -33px;
        right: 306px;
        width: 65px;
        height: 65px;
        font-size: 30px;
        background-color: var(--foreground-back);
        box-shadow:
            0px 1px 30px 0px rgba(#facf1d, 0.1),
            0px 2px 50px 0px rgba(#8dc5fb, 0.1);

        @include respond-below(sm) {
            top: auto;
            bottom: -61px;
            right: 134px;
        }
    }

    &__pencil {
        top: 14px;
        right: 211px;
        width: 81px;
        height: 81px;
        font-size: 35px;
        background-color: var(--foreground-back);
        box-shadow:
            0px 1px 30px 0px rgba(#facf1d, 0.1),
            0px 2px 50px 0px rgba(#8dc5fb, 0.1);

        @include respond-below(sm) {
            top: 62px;
            right: auto;
            left: 16px;
            width: 62px;
            height: 62px;
            font-size: 25px;
        }
    }

    &__bag {
        top: 19px;
        right: 82px;
        width: 56px;
        height: 56px;
        font-size: 30px;
        background-color: var(--foreground-back);
        box-shadow:
            0px 1px 30px 0px rgba(#facf1d, 0.1),
            0px 2px 50px 0px rgba(#8dc5fb, 0.1);

        @include respond-below(sm) {
            top: auto;
            bottom: -43px;
            right: 8px;
        }
    }

    &__smiley {
        bottom: -13px;
        right: -28px;
        width: 94px;
        height: 94px;
        font-size: 40px;
        line-height: 30px;
        background-color: var(--foreground-back);
        transform: rotate(21.26deg);
        box-shadow:
            0px 1px 30px 0px rgba(#facf1d, 0.1),
            0px 2px 50px 0px rgba(#8dc5fb, 0.1);
    }

    .dark-filter {
        box-shadow: 0px 2px 25px 0px rgba(#000000, 0.4);
    }
}
</style>
